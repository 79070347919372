<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    
  },
};
</script>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

@media screen and (max-width: 770px) {
  .el-select-dropdown__item {
    height: 1.2rem !important;
    font-size: 80px !important;
    display: flex;
    align-items: center;
  }
  .el-scrollbar__wrap {
    height: auto !important;
  }
  .el-select-dropdown__wrap {
    max-height: 10000px !important;
  }
  .el-scrollbar .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
