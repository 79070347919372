import { Layout } from '../constants'

export const home = [
  {
    path: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          title: '首页',
          keepAlive: true, isBack: false
        },
      },{
        path: '/healthProgram',
        name: 'healthProgram',
        component: () => import('@/views/healthProgram/web'),
        meta: {
          title: '健康方案付费定制',
        },
      },{
        path: '/tumorCounseling',
        name: 'tumorCounseling',
        component: () => import('@/views/tumorCounseling/web'),
        meta: {
          title: '肿瘤咨询', keepAlive: true, isBack: false
        },
      },{
        path: '/tumorDetails',
        name: 'tumorDetails',
        component: () => import('@/views/tumorDetails/web'),
        meta: {
          title: '肿瘤咨询详情',
        },
      },{
        path: '/companyIntroduction',
        name: 'companyIntroduction',
        component: () => import('@/views/companyIntroduction/web'),
        meta: {
          title: '公司介绍',
        },
      },{
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('@/views/joinUs/web'),
        meta: {
          title: '加入我们',
        },
      },{
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('@/views/contactUs/web'),
        meta: {
          title: '联系我们',
        },
      }
      ,{
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login/index'),
        meta: {
          title: '登录',
        },
      },
    ],
  },
]