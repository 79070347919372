import { Layout } from '../constants'
import { home } from './home'
const RootRoute = {
  path: '/',
  component: Layout,
  redirect: '/home',
}
export const basicRoutes = [
  RootRoute,
  ...home
]
