import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import  "amfe-flexible";
Vue.use(element)
import "animate.css"
import store from './store'
router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    const hasToken =  localStorage.getItem('user')
    if (hasToken) {
      next('/home')
    }else{
      next()
    }
  }else{
    store.state.user =localStorage.getItem('user');
    // const hasToken =  localStorage.getItem('user')
    // if (hasToken) {
      next()
    // }else{
    //   next('/login')
    // }
  }
  // const hasToken = store.state.user.token || localStorage.getItem('SET_TOKEN')
  // if (hasToken) {
  //   next()
  // } else {
  //   next(to.path)
  // }
})
// Vue.use(animate)
new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount('#app')
